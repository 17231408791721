@import ../../../styles/helpers

.item
    display: block
    padding: 32px
    border-radius: 24px
    border: 1px solid $n7
    color: $n2
    transition: all .2s
    &:hover
        border-color: $n6
    +dark
        background: $n2
        border-color: $n2
        color: $n8
        &:hover
            background: $n1

.counter
    margin-bottom: 74px

.icon
    display: flex
    align-items: center
    height: 32px
    margin-bottom: 24px
    svg
        fill: $n4
        +dark
            fill: $n8

.title
    margin-bottom: 4px
    +body-bold-2

.content
    +caption-2
    color: $n4