@import ../../../styles/helpers

.head
    display: flex
    margin-bottom: 64px
    +m
        margin-bottom: 32px
    .button
        flex-shrink: 0
        margin-left: 16px
        +dark
            box-shadow: inset 0 0 0 2px $n3

.title
    margin-right: auto
    +m
        font-size: 32px

.item
    &:not(:last-child)
        margin-bottom: 48px

.category
    margin-bottom: 32px
    +body-bold-2

.fieldset
    & > .field
        &:not(:last-child)
            margin-bottom: 32px
.field
    margin-top: 30px
.row
    display: flex
    margin: 0 -10px
    +m
        display: block
        margin: 0
    &:not(:last-child)
        margin-bottom: 32px

.col
    position: relative
    // flex: 150px 150px calc(50% - 20px)
    // width: calc(50% - 20px)
    margin: 0 10px
    +m
        width: 100%
        margin: 0
    &:not(:last-child)
        +m
            margin-bottom: 20px
    .button
        position: absolute
        right: 12px
        bottom: 8px
        height: 32px
        padding: 0 12px
        font-size: 14px
        +dark
            box-shadow: inset 0 0 0 2px $n3

.label
    margin-bottom: 12px
    +hairline-2
    color: $n5

.controls
    display: flex
    flex-direction: column
    align-items: center
    margin-top: 48px
    padding-top: 48px
    border-top: 1px solid $n6
    +dark
        border-color: $n3
    .button
        margin-right: 40px

.clear
    display: flex
    align-items: center
    +button-1
    color: $n4
    transition: color .2s
    svg
        margin-right: 12px
        fill: $n4
        transition: fill .2s
    &:hover
        color: $p1
        svg
            fill: $p1
