@import ../../../styles/helpers

.title
    margin-bottom: 8px
    +body-bold-1

.head
    display: flex
    align-items: center
    margin-bottom: 40px
    +d
        display: block

.text
    margin-right: auto
    color: $n4
    span
        font-weight: 500
        color: $n2
        +dark
            color: $n8
    
.rating
    position: relative
    top: -3px
    flex-shrink: 0
    +d
        margin: 8px 0 0 -4px

.field
    position: relative

.input
    width: 100%
    height: 72px
    padding: 0 180px 0 24px
    border-radius: 24px
    background: none
    border: 2px solid $n6
    +poppins
    +body-2
    color: $n2
    transition: border-color .2s
    +m
        height: 64px
        padding-right: 96px
    +dark
        border-color: $n3
        color: $n8
    &:focus
        border-color: $n4
    +placeholder
        color: $n4

.button
    position: absolute
    top: 50%
    right: 16px
    z-index: 2
    transform: translateY(-50%)
    +m
        width: 32px
        height: 32px
        padding: 0
        font-size: 0
        svg:last-child
            margin-left: 0 !important

.smile
    position: absolute
    top: 50%
    right: 140px
    z-index: 2
    transform: translateY(-50%)
    +m
        right: 64px
    svg
        fill: $n4
        transition: fill .2s
    &:hover
        svg
            fill: $p1
