@import ../../../../styles/helpers

.card
    border-radius: 16px
    overflow: hidden
    background: $n8
    color: $n2
    +dark
        background: $n1
        color: $n8
    &:hover
        .subtitle
            color: $p1
            +dark
                color: $p1
        .preview
            img
                transform: scale(1.1)

.preview
    position: relative
    overflow: hidden
    border-radius: 16px
    width: 224px
    height: 412px
    top: 0px
    +x
        width: 324px
    +d
        width: 324px
    +t
        width: 324px
    &:before
        content: ""
        display: block
        padding-bottom: 89%
    img
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        object-fit: cover
        transition: transform 1s

.body
    padding: 20px

.line
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    &:not(:last-child)
        margin-bottom: 16px
        padding-bottom: 16px
        border-bottom: 1px solid $n6
        +dark
            border-color: $n3

.subtitle
    +body-bold-2
    transition: color .2s

.location
    +caption-2
    color: $n3
    +dark
        color: $n6

.price
    margin-left: 16px
    padding: 6px 8px
    box-shadow: inset 0 0 0 2px $p4
    border-radius: 4px
    text-align: center
    +hairline-2
    color: $p4

.old
    margin-bottom: 6px
    text-decoration: line-through
    color: $n5

.date
    margin-right: 20px
    +caption-2
    color: $n4

.rating
    display: flex
    align-items: center
    +caption-bold-2
    svg
        margin-right: 4px
        fill: $s3

.category
    position: absolute
    padding: 10px 15px
    border-radius: 20px
    top: 16px
    left: 16px
    z-index: 2
