@import ../../styles/helpers

.date
    position: relative

.head
    position: relative

.box
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%

.icon
    position: absolute
    top: 20px
    left: 16px
    z-index: 2
    display: flex
    justify-content: center
    align-items: center
    width: 32px
    height: 32px
    pointer-events: none
    +d
        left: 10px
    +t
        display: none
    +m
        top: 8px
        left: 0
        display: flex
        width: 24px
    svg
        fill: $n5
        +d
            width: 20px
            height: 20px

.small
    .icon
        +m
            top: 50%
            transform: translateY(-50%)

.description
    position: absolute
    left: 56px
    bottom: 20px
    z-index: 2
    pointer-events: none
    +body-2
    color: $n4
    +d
        left: 48px
        font-size: 14px
    +t
        left: 0
        bottom: 4px
    +m
        left: 32px



