@import ../../../styles/helpers

.item
    display: block
    overflow: hidden
    padding: 8px 8px 24px
    border-radius: 16px
    background: $n8
    text-align: center
    color: $n2
    transition: box-shadow .2s
    &:hover
        box-shadow: inset 0 0 0 1px $n5
    +dark
        background: $n2
        color: $n8
        &:hover
            box-shadow: inset 0 0 0 1px $n4

.avatar
    position: relative
    overflow: hidden
    width: 80px
    height: 80px
    margin: 0 auto 16px
    img
        width: 100%
        height: 100%
        border-radius: 50%
        object-fit: cover
    
.check
    position: absolute
    top: 0
    right: 0
    display: flex
    justify-content: center
    align-items: center
    width: 24px
    height: 24px
    border-radius: 50%
    background: $p4
    svg
        fill: $n8
    
.line
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 16px

.number
    min-width: 39px
    padding: 8px 12px 6px
    border-radius: 13px
    text-align: center
    +hairline-2
    color: $n8

.rating
    display: flex
    justify-content: center
    align-items: center
    margin-left: auto
    +caption-bold-2
    svg
        margin-right: 4px
        fill: $s3
    
.title
    margin-bottom: 4px
    +body-bold-2

.location
    +caption-2
    color: $n4