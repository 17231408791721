@import ../../styles/helpers

.dropdown
    position: relative
    z-index: 3
    &.active
        z-index: 10
        .head
            background: $n8
            color: $p1
            +dark
                background: $n1
            svg
                transform: translateY(-50%) rotate(180deg)
                fill: $p1
        .body
            visibility: visible
            opacity: 1
            transform: translateY(0)

.head
    position: relative
    height: 64px
    padding: 0 64px 0 24px
    border-radius: 32px
    background: transparent
    +dm-sans
    font-size: 48px
    font-weight: 700
    line-height: 64px
    color: $p1
    cursor: pointer
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    transition: all .2s
    +m
        height: 56px
        font-size: 32px
        line-height: 56px
    svg
        position: absolute
        top: 50%
        right: 27px
        transform: translateY(-50%)
        fill: $p1
        transition: all .2s
        +dark
            fill: $p1
    &:hover
        background: $n8
        color: $p1
        svg
            fill: $p1
        +dark
            background: $n1 

.selection
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

.body
    position: absolute
    top: calc(100% + 8px)
    left: 0
    right: 0
    padding: 24px
    border-radius: 24px
    background: $n8
    box-shadow: 0px 40px 64px -32px rgba(15, 15, 15, 0.15)
    visibility: hidden
    opacity: 0
    transform: translateY(3px)
    transition: all .2s
    +dark
        background: $n1
        box-shadow: inset 0 0 0 2px $n3, 0 4px 12px rgba($n1, .1)

.option
    +dm-sans
    font-size: 40px
    font-weight: 700
    line-height: 1.2
    color: $n2
    transition: color .2s
    cursor: pointer
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    +dark
        color: $n4
    +m
        font-size: 32px
    &:hover,
    &.selectioned
        color: $p1
        +dark
            color: $n8
    &:not(:last-child)
        margin-bottom: 8px