@import ../../styles/helpers

.label
    margin-bottom: 12px
    +hairline-2
    color: $n5

.wrap
    position: relative

.input
    width: 100%
    height: 48px
    padding: 0 14px
    border-radius: 12px
    border: 2px solid $n6
    background: none
    +poppins
    +caption-bold-1
    color: $n2
    transition: border-color .2s
    +dark
        border-color: $n3
        color: $n8
    +placeholder
        color: $n4
    &:focus
        border-color: $n4

.toggle
    position: absolute
    top: 0
    right: 0
    bottom: 0
    width: 48px
    svg
        fill: $n5
        transition: fill .2s
    &:hover
        svg
            fill: $n4
    &.active
        svg
            fill: $n1
    +dark
        svg
            fill: $n4
        &:hover
            svg
                fill: $n5
        &.active
            svg
                fill: $n8

.empty
    .input
        height: 26px
        border: none

.view
    .input
        padding-right: 48px