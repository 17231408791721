@import ../../../styles/helpers

.section
    +d
        margin-bottom: 40px

.bg
    height: 254px
    +m
        height: 200px
    img
        width: 100%
        height: 100%
        object-fit: cover

.container
    max-width: 1440px
    +w
        padding: 0 40px
    +m
        padding: 0 8px

.panel
    margin: -175px 0 0
    +d
        margin-top: -140px

.controls
    display: flex
    margin-bottom: 16px
    +m
        display: block
    .button
        +m
            width: 100%
        &:not(:last-child)
            margin-right: 16px
            +m
                margin: 0 0 12px
        &.active
            box-shadow: inset 0 0 0 2px $n2
            background: $n2
            color: $n8

.row
    display: flex
    padding-right: 80px
    +t
        padding-right: 64px
    +m
        display: block
        padding: 0
    & > div
        &:first-child
            flex: 0 0 440px
            +t
                flex: 0 0 280px
        &:nth-child(2)
            flex-grow: 1

.search
    top: auto
    right: 0
    bottom: 4px
    transform: translateY(0)
    +d
        bottom: 8px
    +t
        bottom: 4px
    +m
        position: static
        width: 100%
        height: 48px
        margin-top: 12px
        border-radius: 24px