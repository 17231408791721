@import ../../styles/helpers

.section
    padding-top: 80px
    +d
        padding-top: 64px
    +m
        padding-top: 32px

.container
    display: flex
    align-items: flex-start
    +t
        display: block

.dropdown
    +t
        margin-bottom: 48px
    +m
        margin-bottom: 32px

.menu
    display: flex
    flex-direction: column
    align-items: flex-start
    flex-shrink: 0
    width: 256px
    padding: 48px
    border-radius: 16px
    box-shadow: 0px 32px 32px -12px rgba(15, 15, 15, 0.08)
    +t
        display: none
    +dark
        background: $n2

.link
    display: flex   
    align-items: center
    +button-2
    color: $n4
    transition: color .2s
    svg
        margin-right: 16px
        fill: $n4
    &:hover,
    &.active
        color: $n2
        svg
            fill: $n2
        +dark
            color: $n8
            svg
                fill: $n8
    &:not(:last-child)
        margin-bottom: 40px

.wrapper
    padding-left: 128px
    flex-grow: 1
    +x
        padding-left: 64px
    +d
        padding-left: 32px
    +t
        padding-left: 0