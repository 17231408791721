@import ../../../styles/helpers

.messages
    position: relative
    height: calc(100vh - 88px)
    padding-bottom: 104px
    +x
        padding-bottom: 80px
    +m
        position: fixed
        top: 112px
        left: 0
        right: 0
        bottom: 0
        z-index: 3
        height: auto
        background: $n8
        transform: translateX(100%)
        transition: transform .3s
        +dark
            background: $n1
        &.show
            transform: translateX(0)

.wrapper
    max-height: 100%
    padding: 48px 48px 40px
    overflow: auto
    -webkit-overflow-scrolling: touch
    +x
        padding: 32px 24px
    +t
        padding: 32px

.back
    display: none
    +m
        display: flex
        align-items: center
        margin-bottom: 24px
        +body-bold-1
        svg
            width: 24px
            height: 24px
            margin-right: 10px
            fill: $n2
        +dark
            color: $n8
            svg
                fill: $n8

.form
    position: absolute
    left: 48px 
    right: 48px
    bottom: 48px
    +x
        left: 24px
        right: 24px
        bottom: 24px

.date
    margin-bottom: 24px
    text-align: center
    +caption-bold-2
    +x
        margin-bottom: 0

.list
    display: flex
    flex-direction: column
    align-items: flex-start

.author
    display: flex
    margin-top: 24px
    .message
        margin-top: 0
        background: $n7
        color: $n2
        +dark
            background: $n2
            color: $n8

.avatar
    flex-shrink: 0
    width: 32px
    height: 32px
    margin-right: 16px
    img
        width: 100%
        height: 100%
        object-fit: cover
        border-radius: 50%

.message
    margin-top: 24px
    margin-left: auto
    padding: 16px 24px
    border-radius: 32px
    background: $p1
    color: $n8

.time
    width: 100%
    margin-top: 8px
    text-align: right
    +caption-bold-2
    color: $n5
   