@import ../../../../../styles/helpers

.wrapper
    margin: 0 -16px
    +m
        margin: 0 -8px

.slide
    height: 100%

.item
    height: 100%
    padding: 32px
    border-radius: 24px
    +d
        padding: 24px
    +dark
        color: $n2

.icon
    margin-bottom: 64px
    +t
        margin-bottom: 32px

.title
    margin-bottom: 4px
    +body-bold-1

.text
    +caption-2