@import ../../../../styles/helpers

.list
    position: relative

.title
    margin-bottom: 24px
    +body-bold-1

.nav
    display: flex
    margin-bottom: 32px

.link
    display: flex
    padding: 6px 12px
    border-radius: 14px
    background: none
    +button-2
    color: $n4
    transition: all .2s
    svg
        margin-right: 8px
        fill: $n4
        transition: fill .2s
    &:hover
        color: $n3
        svg
            fill: $n3
        +dark
            color: $n6
    &.active
        background: $n3
        color: $n8
        svg
            fill: $n8
        +dark
            background: $n3
            color: $n8
    &:not(:last-child)
        margin-right: 8px

.wrapper
    margin: 0 -16px
    +d
        margin: 0 -8px

