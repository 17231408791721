@import ../../../styles/helpers

.section
    +d
        margin-bottom: 96px
    +m
        margin-bottom: 64px

.container
    max-width: 1440px
    +w
        padding: 0 40px
    +t
        padding: 0
    +m
        padding: 0 8px

.preview
    position: relative
    &:before
        content: ""
        display: block
        padding-bottom: 60.63%
        +d
            padding-bottom: 64%
        +m
            display: none
    // div
    //     // position: absolute
    div
        background-image: linear-gradient(143.18deg, rgba(2, 139, 217, 0.84) 1.63%, #68B8F8 30.41%, rgba(236, 248, 252, 0) 69.27%)
        background-size: cover
        // mix-blend-mode: normal
        top: 0
        left: 0
        width: 100%
        height: 100%
        border-radius: 24px
        object-fit: cover
        +t
            border-radius: 0
        +m
            // position: static
            background-image: linear-gradient(130.48deg, rgba(194, 240, 255, 0.84) 22.56%, rgba(236, 248, 252, 0) 69.73%), linear-gradient(130.48deg, rgba(194, 240, 255, 0.84) 22.56%, rgba(236, 248, 252, 0) 69.73%)
            height: 780px
            border-radius: 16px 16px 0 0
            object-position: 50% 100%
    img
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        border-radius: 24px
        object-fit: cover
        +t
            border-radius: 0
        +m
            content: url('main-hero-mobile.png')
            position: static
            height: 780px
            border-radius: 16px 16px 0 0
            object-position: 25% 100%

.wrap
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    padding: 145px 80px
    +d
        padding: 98px 80px
    +t
        padding: 80px 40px
    +m
        padding: 80px 15px
        text-align: center

.title
    font-family: 'DM Sans'
    font-style: normal
    font-weight: 700
    font-size: 58px
    line-height: 64px

    letter-spacing: -0.02em
    margin-left: 0px
    max-width: 650px
    margin-bottom: 16px
    +dark
        color: $n2

.title2
    font-family: 'DM Sans'
    font-style: normal
    font-weight: 700
    font-size: 58px
    line-height: 64px
    background-image: radial-gradient(circle, #f89e33, #f98f30, #fa7f30, #fa6f32, #f95d36)
    //color: #F49316
    -webkit-background-clip: text
    display: inline-block
    //padding: 14px;
    -webkit-text-fill-color: #00000000
    letter-spacing: -0.02em
    margin-left: 0px
    max-width: 650px
    margin-bottom: 16px
    // +dark
    //     color: $n2

.info
    font-family: 'DM Sans'
    font-style: normal
    font-weight: 500
    font-size: 21px
    line-height: 24px
    color: #23262F
    margin-bottom: 24px
    // color: $n2
    +d
        font-size: 16px

// .hero
//     background-image: url('main-gradient.jpg')
    // background-size: cover
    // mix-blend-mode: normal
    // width: 800px
    // height: 600px
    // background-image: -webkit-linear-gradient( 90deg, rgb(252, 227, 138) 0%, rgb(243, 129, 129) 100%)
    // background-image: -ms-linear-gradient( 90deg, rgb(252, 227, 138) 0%, rgb(243, 129, 129) 100%)
    // mix-blend-mode: normal

.panel
    margin: -175px 80px 0
    +w
        margin: -175px 40px 0
    +d
        margin-top: -148px
    +t
        margin: -110px 16px 0
    +m
        margin: -180px 8px 0

.body
    margin-top: 24px
    +d
        margin-top: 16px
.row
    display: flex
    justify-content: space-around
    align-items: center
    +x
        margin: 0 -10px
    +t
        margin: 0
    +m
        display: flex
        flex-direction: column
    // & > div
    //     &:first-child
    //         flex: 0 0 300px
    //         +w
    //             flex: 0 0 270px
    //         +d
    //             flex: 0 0 250px
    //         +t
    //             flex: 0 0 220px
    //     &:nth-child(2)
    //         flex-grow: 1.2
    //     &:nth-child(4)
    //         flex-grow: 1
    //     &:nth-child(3)
    //         flex: 0 0 296px
    //         +d
    //             flex: 0 0 240px
    //         +t
    //             flex: 0 0 170px
