@import ../../../styles/helpers

.section
    padding-bottom: 136px
    border-bottom: 1px solid $n6
    +d
        margin-bottom: 80px
        padding-bottom: 80px
    +m
        margin-bottom: 64px
        padding-bottom: 64px
    +dark
        border-color: $n2

.wrapper
    display: flex
    align-items: flex-start
    +t
        display: block

.details
    flex: 0 0 calc(100% - 448px)
    max-width: calc(100% - 448px)
    padding-right: 48px
    +d
        flex: 0 0 calc(100% - 400px)
        max-width: calc(100% - 400px)
        overflow: hidden 
    +t
        max-width: 100%
        margin-bottom: 48px
        padding: 0
        overflow: visible 

.receipt
    position: relative
    z-index: 3
    flex-shrink: 0
    +m
        margin: 0 -16px

.item
    display: flex
    align-items: center
    &:not(:last-child)
        margin-bottom: 24px
    .button
        width: 192px
        color: $n4
        +m
            width: 180px
            padding: 0 16px
        svg
            fill: $n4
        &:hover
            background: $p1
            box-shadow: inset 0 0 0 2px $p1
            color: $n8
            svg
                fill: $n8
            .price
                color: $n8

.wrap
    flex-grow: 1

.date
    +body-bold-2

.time
    +caption-2
    color: $n4

.price
    color: $p4
    transition: color .2s

.foot
    margin-top: 48px
    text-align: center

.more
    +button-1
    color: $n2
    transition: color .2s
    &:hover
        color: $p1
    +dark
        color: $n8
        &:hover
            color: $p1