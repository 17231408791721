@import ../../../styles/helpers

.control
    margin-bottom: 48px
    +m
        margin-bottom: 24px

.head
    margin-bottom: 64px
    +m
        margin-bottom: 32px

.title
    margin-bottom: 12px

.counter
    margin-bottom: 48px
    +body-bold-2
    color: $n4
    +m
        margin-bottom: 32px

.line
    display: flex
    align-items: center
    +m
        display: block
    & > div
        &:not(:last-child)
            +m
                margin-bottom: 12px

.nav
    display: flex
    justify-content: center
    margin-right: auto
    +m
        display: none

.link
    display: flex
    padding: 6px 12px
    border-radius: 14px
    background: none
    +button-2
    color: $n4
    transition: all .2s
    svg
        margin-right: 8px
        fill: $n4
        transition: fill .2s
    &:hover
        color: $n3
        svg
            fill: $n3
        +dark
            color: $n6
    &.active
        background: $n3
        color: $n8
        svg
            fill: $n8
        +dark
            background: $n8
            color: $n2
    &:not(:last-child)
        margin-right: 8px

.dropdown
    flex-shrink: 0
    width: 256px
    margin-left: auto
    +m
        width: 100%
        margin: 0

.list
    display: flex
    flex-wrap: wrap
    margin: -30px -15px 0
    +m
        display: block
        margin: 0

.card
    flex: 0 0 calc(33.333% - 30px)
    width: calc(33.333% - 30px)
    margin: 30px 15px 0
    +t
        flex: 0 0 calc(50% - 30px)
        width: calc(50% - 30px)
    +m
        width: 100%
        margin: 32px 0 0

.flight
    &:not(:last-child)
        margin-bottom: 24px

.btns
    margin-top: 48px
    text-align: center
    +m
        margin-top: 32px
    .button
        .loader
            margin: 0 16px 0 2px
            transform: scale(.8)
