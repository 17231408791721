@import ../../styles/helpers

.card
    display: flex
    flex-direction: column
    border-radius: 24px
    overflow: hidden
    background: $n8
    border: 1px solid $n6
    color: $n1
    transition: border-color .2s
    &:hover
        border-color: $n5
        .preview
            img
                transform: scale(1.1)
    +dark
        background: $n1
        border-color: $n3
        color: $n8
    &.row
        flex-direction: row
        border-radius: 16px
        +x
            display: block
        .preview
            flex-shrink: 0
            width: 256px
            +x
                width: 100%
            &:before
                display: none
                +x
                    display: block
        .body
            display: flex
            flex-direction: column
            flex-grow: 1
        .line
            margin-bottom: 16px
        .price
            display: flex
            flex-direction: column-reverse
        .old
            margin: 6px 0 0
            text-decoration: none
        .options
            flex-wrap: nowrap
            +x
                flex-wrap: wrap
        .option
            &:nth-child(2)
                flex-shrink: 0
        .foot
            margin-top: auto
    &.car
        .line
            margin-bottom: 0
            +d
                margin-bottom: 8px
        .price
            display: flex
            flex-direction: column-reverse
        .old
            margin: 6px 0 0
            text-decoration: none
        .option
            &:nth-child(2)
                flex-shrink: 0

.preview
    position: relative
    overflow: hidden
    &:before
        content: ""
        display: block
        padding-bottom: 68.6%
    img
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        object-fit: cover
        transition: transform 1s

.category
    position: absolute
    top: 16px
    left: 16px
    z-index: 2

.body
    flex-grow: 1
    padding: 24px 22px

.line
    display: flex
    justify-content: space-between
    align-items: flex-start
    margin-bottom: 8px

.title
    +body-bold-2

.price
    margin-left: 16px
    padding: 6px 8px
    box-shadow: inset 0 0 0 2px $p4
    border-radius: 4px
    text-align: center
    +hairline-2
    color: $p4

.old
    margin-bottom: 6px
    text-decoration: line-through
    color: $n5

.date
    margin-right: 20px
    +caption-2
    color: $n4

.options
    display: flex
    flex-wrap: wrap
    margin: -8px 0 16px -12px

.option
    display: flex
    margin: 8px 0 0 12px
    +caption-2
    color: $n4
    svg
        flex-shrink: 0
        margin: 1px 6px 0 0
        fill: $n4

.foot
    padding-top: 16px
    border-top: 1px solid $n6
    +dark
        border-color: $n2

.comment
    display: flex
    align-items: flex-start
    margin-bottom: 20px
    +caption-2
    color: $n4

.avatar
    flex-shrink: 0
    width: 32px
    height: 32px
    margin-right: 12px
    img
        width: 100%
        height: 100%
        border-radius: 50%
        object-fit: cover

.flex
    display: flex
    justify-content: space-between

.cost
    +caption-bold-2

.rating
    display: flex
    align-items: center
    svg
        margin-right: 4px
        fill: $s3

.number
    margin-right: 4px
    +caption-bold-2

.review
    +caption-2
    color: $n4

    