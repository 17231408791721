@import ../../../styles/helpers

.section
    +d
        margin-bottom: 96px
    +m
        margin-bottom: 64px

.container
    max-width: 1440px
    +w
        padding: 0 40px
    +t
        padding: 0
    +m
        padding: 0 8px

.preview
    position: relative
    &:before
        content: ""
        display: block
        padding-bottom: 60.63%
        +d
            padding-bottom: 64%
        +m
            display: none
    img
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        border-radius: 24px
        object-fit: cover
        +t
            border-radius: 0
        +m
            position: static
            height: 700px
            border-radius: 16px 16px 0 0
            object-position: 50% 100%
    
.wrap
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    padding: 105px 80px 245px
    text-align: center
    +d
        padding: 64px 80px 200px
    +t
        padding: 80px 40px 180px
    +m
        display: block
        padding: 48px 32px
        text-align: center

.title
    margin-bottom: 16px
    +dark
        color: $n2

.info
    margin-bottom: 24px
    color: $n2
    +m
        font-size: 24px

.panel
    margin: -175px 80px 0
    +w
        margin: -175px 40px 0
    +d
        margin-top: -148px
    +t
        margin: -110px 16px 0
    +m
        margin: -205px 8px 0

.body
    margin-top: 32px
    +m
        margin-top: 24px

.controls
    display: flex
    margin-bottom: 16px
    +m
        display: block
    .button
        +m
            width: 100%
        &:not(:last-child)
            margin-right: 16px
            +m
                margin: 0 0 12px
        &.active
            box-shadow: inset 0 0 0 2px $n2
            background: $n2
            color: $n8
            +dark
                box-shadow: inset 0 0 0 2px $n8
                background: $n8
                color: $n2

.row
    display: flex
    padding-right: 100px
    +x
        margin: 0 -20px
    +t
        margin: 0
        padding-right: 80px
    +m
        display: block
        padding: 0
    & > div
        &:first-child
            flex: 0 0 340px
            +t
                flex: 0 0 270px
        &:nth-child(2)
            flex-grow: 1

.search
    top: auto
    bottom: 16px
    transform: translateY(0)
    +t
        bottom: 0
    +m
        position: static
        width: 100%
        height: 48px
        margin-top: 12px
        border-radius: 24px