@import ../../../styles/helpers

.title
    margin-bottom: 64px
    +m
        margin-bottom: 32px
        font-size: 32px

.box
    &:not(:last-child)
        margin-bottom: 64px
        padding-bottom: 64px
        border-bottom: 1px solid $n6
        +m
            margin-bottom: 32px
            padding-bottom: 32px
        +dark
            border-color: $n3

.category
    margin-bottom: 16px
    +body-bold-1

.row
    display: flex
    margin: 0 -48px
    +m
        display: block
        margin: 0
    .item
        flex: 0 0 50%
        padding: 0 48px
        +m
            padding: 0
        &:first-child
            border-right: 1px solid $n6
            +m
                margin-bottom: 16px
                border: none
            +dark
                border-color: $n3

.group
    .item
        &:not(:last-child)
            margin-bottom: 20px
            padding-bottom: 20px
            border-bottom: 1px solid $n6
            +dark
                border-color: $n3

.item
    display: flex
    align-items: center
    +m
        align-items: flex-start

.details
    flex-grow: 1

.label
    margin-bottom: 4px
    font-weight: 500

.text
    +caption-2
    color: $n4

.button
    flex-shrink: 0
    margin-left: 12px
    +dark
        box-shadow: inset 0 0 0 2px $n3