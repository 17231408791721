@import ../../styles/helpers

.control
    margin-bottom: 80px
    +t
        margin-bottom: 64px
    +m
        margin-bottom: 24px

.row
    display: flex
    align-items: flex-start
    margin: 0 -16px
    +t
        display: block
        margin: 0

.col
    flex: 0 0 calc(50% - 32px)
    width: calc(50% - 32px)
    margin: 0 16px
    +t
        width: 100%
        margin: 0
    &:nth-child(2)
        padding-left: 48px
        +d
            padding-left: 0
    &:not(:last-child)
        +t
            margin-bottom: 48px
        +m
            margin-bottom: 32px

