@import ../../../styles/helpers

.line
    display: flex
    align-items: center
    margin-bottom: 40px

.subtitle
    margin-right: auto
    +body-bold-2

.cards
    display: flex
    align-items: center

.fieldset
    & > .field
        margin-bottom: 32px
    
.row
    display: flex
    margin: 0 -8px
    .field
        flex: 0 0 calc(50% - 16px)
        width: calc(50% - 16px)
        margin: 0 8px

.checkbox
    margin-top: 32px

.message
    margin-top: 24px
    
.category
    +body-bold-1
    margin-bottom: 24px

.button
    margin-top: 32px