@import ../../../styles/helpers

.photo
    margin: 0 -80px
    +d
        margin: 0 -40px
    +t
        display: none
    img
        width: 100%
        border-radius: 24px

.row
    display: flex
    align-items: flex-start
    +t
        display: block

.profile
    position: relative
    z-index: 3
    flex-shrink: 0
    width: 352px
    margin-top: -125px
    +d
        width: 300px
        padding: 32px 16px 
    +t
        width: auto
        margin: 0 0 48px
    +m
        margin: 0 -16px 32px

.line
    +m
        display: flex
        justify-content: center
        align-items: center

.avatar
    position: relative
    flex-shrink: 0
    width: 160px
    height: 160px
    margin: 0 auto 24px
    +m
        width: 64px
        height: 64px
        margin: 0 20px 0 0
    img
        width: 100%
        height: 100%
        border-radius: 50%
        object-fit: cover

.check
    position: absolute
    top: 0
    right: 0
    display: flex
    justify-content: center
    align-items: center
    width: 40px
    height: 40px
    border-radius: 50%
    background: $p4
    +m
        width: 24px
        height: 24px
    svg
        fill: $n8

.man
    margin-bottom: 8px
    text-align: center
    +dm-sans
    font-size: 32px
    line-height: (40/32)
    letter-spacing: -.01em
    +m
        text-align: left

.rating
    display: flex
    justify-content: center
    align-items: center
    +m
        justify-content: flex-start
    svg
        margin-right: 8px
        fill: $s3
    
.number
    font-weight: 500

.reviews
    margin-left: 8px
    color: $n4

.wrapper
    flex: 0 0 calc(100% - 352px)
    width: calc(100% - 352px)
    padding: 80px 0 0 80px
    +x
        padding-left: 48px
    +d
        flex: 0 0 calc(100% - 300px)
        width: calc(100% - 300px)
        padding: 48px 0 0 32px
    +t
        width: 100%
        padding: 0

.details
    margin-bottom: 64px

.list
    margin-bottom: 104px
    +m
        margin-bottom: 64px