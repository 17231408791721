@import ../../../styles/helpers

.section
    +m
        margin-bottom: 32px

.bg
    height: 254px
    +m
        height: 200px
    img
        width: 100%
        height: 100%
        object-fit: cover

.container
    max-width: 1440px
    +w
        padding: 0 40px
    +m
        padding: 0 8px

.panel
    margin: -175px 0 0
    +d
        margin-top: -140px

.controls
    display: flex
    margin-bottom: 16px

.nav
    display: flex
    justify-content: center
    margin-right: 16px
    +m
        margin-right: 8px

.link
    display: flex
    padding: 12px 16px
    border-radius: 20px
    background: none
    box-shadow: inset 0 0 0 2px $n6
    +button-2
    color: $n2
    transition: all .2s
    +m
        padding: 12px
    svg
        margin-right: 8px
        fill: $n4
        transition: fill .2s
    +dark
        box-shadow: inset 0 0 0 2px $n4
        color: $n8
    &:hover
        box-shadow: inset 0 0 0 2px $n2
        +dark
            background: $n3
            box-shadow: inset 0 0 0 2px $n3
    &.active
        background: $n2
        box-shadow: inset 0 0 0 2px $n2
        color: $n8
        svg
            fill: $n8
        +dark
            background: $n8
            color: $n2
    &:not(:last-child)
        margin-right: 16px
        +m
            margin-right: 8px

.row
    display: flex
    padding-right: 80px
    +x
        margin: 0 -20px
    +t
        margin: 0
        padding-right: 64px
    +m
        display: block
        padding: 0
    & > div
        &:first-child,
        &:nth-child(2)
            flex: 0 0 280px
            +x
                flex: 0 0 250px
            +d
                flex: 0 0 230px
            +t
                flex: 0 0 140px
        &:nth-child(3)
            flex-grow: 1

.search
    top: auto
    right: 0
    bottom: 0
    transform: translateY(0)
    +d
        bottom: 8px
    +t
        bottom: 4px
    +m
        position: static
        margin-top: 12px
        width: 100%
        height: 48px
        border-radius: 24px