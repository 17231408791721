@import ../../../styles/helpers

.head
    display: flex
    align-items: flex-end
    margin-bottom: 80px
    +d
        margin-bottom: 64px
    +m
        display: block
        margin-bottom: 32px
.body
    display: flex
    align-items: flex-end
    align-content: space-around
    flex-wrap: nowrap
    justify-content: space-around
    margin-bottom: 80px
    +d
        margin-bottom: 64px
    +m
        display: block
        margin-bottom: 32px

.details
    max-width: 540px
    margin-right: auto

.button
    +m
        width: 100%
        margin-top: 32px

.preview
    position: relative
    display: flex
    align-content: center
    margin: -22px
    border-radius: 16px
    overflow: hidden
    align-items: center
    +x
        // margin: 0 -40px
    +t
        border-radius: 0
    +m
        // margin-left: -15px
        // margin: 0 -22px
        // &:before
        //     content: ""
        //     display: block
        //     padding-bottom: 65%
    img
        width: 100%
        +m
            position: absolute
            top: 0
            left: 0
            height: 100%
            object-fit: cover

.iframe
    +x
        width: 880px
        height: 300px
    +t
        width: 480px
        height: 300px
    +m
        width: 380px
        height: 300px
