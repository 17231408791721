@import ../../styles/helpers

.travelers
    position: relative
    &.active
        overflow: visible
        .head
            box-shadow: 0px 32px 32px 0 rgba(15, 15, 15, 0.08)
            +t
                box-shadow: none
            +dark
                background: $n1
                +t
                    background: none
        .body
            visibility: visible
            opacity: 1

.head
    position: relative
    border-radius: 16px
    user-select: none
    transition: all .2s

.content
    display: flex
    width: 100%
    height: 96px
    padding: 20px 116px 0 56px
    border-radius: 16px
    +text-overflow
    +body-bold-1
    color: $n2
    cursor: pointer
    +x
        padding-right: 96px
    +d
        padding: 25px 96px 0 48px
        font-size: 18px
    +t
        height: 64px
        padding: 12px 64px 0 0
    +m
        padding-left: 32px
    +dark
        color: $n8

.small
    .content
        height: 72px
        padding: 0 116px 0 64px
        line-height: 72px
        +d
            padding: 0 92px 0 48px
        +t
            height: 64px
            padding: 0 72px 0 0
            line-height: 66px
        +m
            padding-left: 32px
    .icon
        +m
            top: 50%
            transform: translateY(-50%)

.icon
    position: absolute
    top: 20px
    left: 16px
    display: flex
    justify-content: center
    align-items: center
    width: 32px
    height: 32px
    pointer-events: none
    +d
        left: 10px
    +t
        display: none
    +m
        top: 8px
        left: 0
        display: flex
        width: 24px
    svg
        fill: $n5
        +d
            width: 20px
            height: 20px

.description
    position: absolute
    left: 56px
    bottom: 20px
    pointer-events: none
    +body-2
    color: $n4
    +d
        left: 48px
        font-size: 14px
    +t
        left: 0
        bottom: 4px
    +m
        left: 32px

.body
    position: absolute
    right: 0
    bottom: calc(100% + 16px)
    z-index: 10
    width: 426px
    padding: 16px 40px
    box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12)
    border-radius: 24px
    background: $n8
    visibility: hidden
    opacity: 0
    transition: all .2s
    +d
        width: 350px
        padding: 8px 24px
    +t
        box-shadow: 0px 16px 32px rgba(15, 15, 15, 0.12)
    +m
        bottom: 100%
        left: -24px
        right: -24px
        width: auto
    +dark
        background: $n1
    
.box
    display: flex
    align-items: flex-start
    padding: 24px 0 16px
    +m
        padding: 16px 0 12px
    &:not(:last-child)
        border-bottom: 1px solid $n6
        +dark
            border-color: $n3

.details
    flex-grow: 1
    padding-right: 24px

.category
    +body-bold-2

.text
    +caption-2
    color: $n4

.bodyDown
    .body
        top: calc(100% + 16px)
        bottom: auto 
        +m
            top: 100%
            bottom: auto
