@import ../../styles/helpers

.profile
    padding: 32px 20px
    border: 1px solid $n6
    border-radius: 24px
    background: $n8
    +m
        padding: 24px 16px
    +dark
        border-color: $n3
        background: $n1

.head
    margin-bottom: 32px

.body
    display: flex
    flex-direction: column
    align-items: center

.parameters
    display: flex
    justify-content: center
    margin-bottom: 32px
    padding: 8px 16px
    border-radius: 20px
    background: $n7
    +dark
        background: $n2
    
.parameter
    display: flex
    align-items: center
    color: $n4
    svg
        margin-right: 6px
        fill: $n4
    &:not(:last-child)
        margin-right: 12px
    
.info
    margin-bottom: 32px
    text-align: center
    color: $n4

.site
    display: inline-flex
    align-items: center
    margin-bottom: 32px
    +button-2
    color: $n2
    +dark
        color: $n8
    svg
        margin-right: 8px
        fill: $n4

.control
    display: inline-block
    position: relative
    margin-bottom: 32px

.btns
    display: flex
    justify-content: center
    align-items: center

.button
    span
        &:nth-child(2)
            display: none
    &.active
        background: $n2
        box-shadow: 0 0 0 2px $n2 inset
        +dark
            background: $n3
            box-shadow: 0 0 0 2px $n3 inset
        svg
            fill: $n8
        span
            &:first-child
                display: none
            &:nth-child(2)
                display: inline
    +dark
        box-shadow: inset 0 0 0 2px $n3
    &:not(:last-child)
        margin-right: 8px
    
.socials
    display: flex
    justify-content: center

.social
    svg
        fill: $n4
        transition: fill .2s
    &:hover
        svg
            fill: $p1
    &:not(:last-child)
        margin-right: 24px
    
.note
    position: relative
    margin-top: 32px
    padding-top: 32px
    +caption-2
    color: $n4
    &:before
        content: ""
        position: absolute
        top: 0
        left: 50%
        width: 200px
        height: 1px
        transform: translateX(-50%)
        background: $n6
        +dark
            background: $n3
    +dark
        border-color: $n3
    
.report
    display: inline-flex
    align-items: center
    margin-top: 32px
    +poppins
    +caption-2
    color: $n4
    transition: color .2s
    svg
        margin-right: 8px
        fill: $n4
        transition: fill .2s
    &:hover
        color: $p1
        svg
            fill: $p1
