@import ../../../styles/helpers

.control
    margin-bottom: 48px
    +m
        margin-bottom: 24px

.title
    margin-bottom: 64px
    +m
        margin-bottom: 32px

.list
    display: flex
    flex-wrap: wrap
    margin: -30px -15px 0
    +m
        display: block
        margin: 0

.card
    flex: 0 0 calc(50% - 30px)
    width: calc(50% - 30px)
    margin: 30px 15px 0
    +m
        width: 100%
        margin: 32px 0 0
    &:nth-child(n+5)
        display: none

.btns
    margin-top: 48px
    text-align: center
    +m
        margin-top: 32px
    .button
        .loader
            margin: 0 16px 0 2px
            transform: scale(.8)
