@import ../../styles/helpers

.grid
    display: flex
    margin-left: -32px

.column
    padding-left: 32px

.preview
    margin-top: 32px
    +m
        margin-top: 16px
    img
        width: 100%
        border-radius: 16px

.foot
    margin-top: 32px
    text-align: center

.head
    display: flex
    align-items: flex-start
    margin-bottom: 64px
    +m
        display: block
        margin-bottom: 48px

.box
    flex-grow: 1
    padding-right: 40px
    +m
        margin-bottom: 32px
        padding-right: 0
.title
    max-width: 640px
    margin-bottom: 16px
    +m
        font-size: 32px
