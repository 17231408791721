@import ../../../styles/helpers

.section
    +d
        margin-bottom: 96px
    +m
        margin-bottom: 64px

.container
    max-width: 1440px
    +w
        padding: 0 40px
    +t
        padding: 0
    +m
        padding: 0 8px

.preview
    position: relative
    &:before
        content: ""
        display: block
        padding-bottom: 60.63%
        +d
            padding-bottom: 64%
        +m
            display: none
    img
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        border-radius: 24px
        object-fit: cover
        +t
            border-radius: 0
        +m
            position: static
            height: 700px
            border-radius: 16px 16px 0 0
            object-position: 50% 100%
    
.wrap
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    padding: 145px 80px
    +d
        padding: 98px 80px
    +t
        padding: 80px 40px
    +m
        padding: 80px 32px
        text-align: center

.title
    max-width: 450px
    margin-bottom: 16px
    +dark
        color: $n2

.info
    margin-bottom: 24px
    color: $n2
    +d
        font-size: 16px
    +m
        font-size: 24px

.panel
    margin: -175px 80px 0
    +w
        margin: -175px 40px 0
    +d
        margin-top: -148px
    +t
        margin: -110px 16px 0
    +m
        margin: -210px 8px 0

.body
    margin-top: 32px
    +d
        margin-top: 24px

.controls
    display: flex
    margin-bottom: 16px
    +m
        flex-wrap: wrap
    .button
        +m
            margin-bottom: 12px
            padding: 0 24px
        &:not(:last-child)
            margin-right: 16px
            +m
                margin-right: 8px
        &.active
            box-shadow: inset 0 0 0 2px $n6
            background: $n6
            color: $n2 

.row
    display: flex
    padding-right: 100px
    +x
        margin: 0 -20px
        padding-right: 90px
    +t
        margin: 0
        padding-right: 70px
    +m
        flex-wrap: wrap
        padding-right: 0
    & > div
        &:first-child,
        &:nth-child(2)
            flex: 0 0 248px
            +x
                flex: 0 0 225px
            +d
                flex: 0 0 200px
            +t
                flex: 0 0 170px
            +m
                flex: 0 0 50%
        &:nth-child(3)
            flex-grow: 1

.search
    top: auto
    bottom: 16px
    transform: translateY(0)
    +x
        right: 0
    +t
        bottom: 0
    +m
        position: static
        width: 100%
        height: 48px
        margin-top: 12px
        border-radius: 24px