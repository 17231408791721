@import ../../../../styles/helpers

.item
    display: flex
    align-items: flex-start
    padding: 24px 20px
    border-radius: 16px
    cursor: pointer
    transition: all .2s
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    +d
        padding: 8px
        border-radius: 50%
    +m
        padding: 24px 20px
        border-radius: 16px
    &:not(:last-child)
        margin-bottom: 12px
        +d
            margin-bottom: 24px
        +m
            margin-bottom: 12px
    &:hover,
    &.active
        box-shadow: 0px 40px 32px -8px rgba(15, 15, 15, 0.12)
        +dark
            background: $n2

.avatar
    flex-shrink: 0
    width: 48px
    height: 48px
    margin-right: 16px
    +d
        margin-right: 0
    +m
        margin-right: 16px
    img
        width: 100%
        height: 100%
        object-fit: cover
        border-radius: 50%

.details
    flex: 0 0 calc(100% - 64px)
    max-width: calc(100% - 64px)
    +d
        display: none
    +m
        display: block

.head
    display: flex
    margin-bottom: 4px

.man
    margin-right: auto
    +body-bold-2

.rating
    margin-left: 8px
    display: flex
    justify-content: center
    align-items: center
    flex-shrink: 0
    width: 50px
    height: 24px
    border-radius: 12px
    background: $n8
    box-shadow: inset 0 0 0 1px $n6
    +caption-bold-2
    +dark
        background: $n2
        box-shadow: inset 0 0 0 1px $n3
    svg
        margin-right: 4px
        fill: $s3

.date
    margin-bottom: 4px
    +caption-2
    color: $n4

.message
    +text-overflow
    +caption-2