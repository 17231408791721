@import ../../styles/helpers

.control
    margin-bottom: 80px
    +t
        margin-bottom: 48px

.wrapper
    display: flex
    align-items: flex-start
    +t
        flex-direction: column-reverse
        align-items: stretch 
    +m  
        margin: 0 -16px

.confirm
    flex-grow: 1
    padding-right: 80px
    +x
        padding-right: 48px
    +d
        padding-right: 32px
    +t
        padding: 0

.price
    flex-shrink: 0
    width: 448px
    +d
        width: 416px
    +t
        width: auto
        margin-bottom: 48px

