@import ../../styles/helpers

.sorting
    padding-bottom: 80px
    +d
        padding-bottom: 48px
    +m
        padding-bottom: 32px

.control
    margin-bottom: 48px
    padding: 0
    +m
        display: none !important

.body
    display: flex
    align-items: flex-start
    margin-bottom: 32px
    padding-bottom: 32px
    border-bottom: 1px solid $n6
    +dark
        border-color: $n3
    .button
        margin-left: 40px

.box
    margin-right: auto

.line
    display: flex
    align-items: center

.location
    position: relative
    +m
        display: none
    .button
        &.active
            background: $p1
            box-shadow: inset 0 0 0 2px $p1
            color: $n8
            svg
                transform: rotate(180deg)
                fill: $n8
        +dark
            box-shadow: inset 0 0 0 2px $n3
            &:hover
                background: $n3
            &.active
                background: $p1
                box-shadow: inset 0 0 0 2px $p1


.map
    position: absolute
    top: calc(100% + 16px)
    right: 0
    z-index: 5
    width: 640px
    height: 480px
    padding: 4px
    background: $n8
    border-radius: 24px 0 24px 24px
    border: 1px solid $n6
    box-shadow: 0px 40px 64px -16px rgba(15, 15, 15, 0.1)
    visibility: hidden
    opacity: 0
    transition: all .4s
    &.show
        visibility: visible
        opacity: 1
    iframe
        width: 100%
        height: 100%
        border-radius: 21px 0 21px 21px


.title
    margin-bottom: 12px
    +m
        font-size: 32px

.sale
    margin-right: 16px
    padding: 8px 8px 6px
    box-shadow: inset 0 0 0 2px $p4
    border-radius: 4px
    +hairline-2
    color: $p4

.details
    +body-bold-2

.foot
    display: flex
    justify-content: space-between
    align-items: center
    +m
        display: block
    .dropdown
        width: 256px
        +m
            width: 100%
    & > div
        &:nth-child(2)
            +m
                margin-bottom: 16px

.nav
    display: flex
    justify-content: center
    margin-right: auto
    +t
        display: none

.link
    display: flex
    padding: 6px 12px
    border-radius: 14px
    background: none
    +button-2
    color: $n4
    transition: all .2s
    &:hover
        color: $n3
        +dark
            color: $n6
    &.active
        background: $n3
        color: $n8
        +dark
            background: $n6
            color: $n2
    &:not(:last-child)
        margin-right: 8px

