@import ../../../styles/helpers

.control
    padding: 0
    +m
        display: none

.sorting
    display: flex
    align-items: center
    margin: 32px 0 64px
    padding-top: 32px
    border-top: 1px solid $n6
    +m
        display: block
        margin: 0 0 32px
        padding-top: 0
        border: none
    +dark
        border-color: $n3

.box
    flex-shrink: 0
    width: 256px
    margin-right: auto
    +m
        width: 100%
        margin: 0 0 12px
    .button
        width: 100%
        margin-top: 12px
        &.active
            background: $n2
            box-shadow: inset 0 0 0 2px $n2
            color: $n8
            +dark
                background: $p1
                box-shadow: inset 0 0 0 2px $p1

.nav
    display: flex
    justify-content: center
    +m
        display: none

.link
    display: flex
    padding: 6px 12px
    border-radius: 14px
    background: none
    +button-2
    color: $n4
    transition: all .2s
    svg
        margin-right: 8px
        fill: $n4
        transition: fill .2s
    &:hover
        color: $n3
        svg
            fill: $n3
        +dark
            color: $n6
    &.active
        background: $n3
        color: $n8
        svg
            fill: $n8
        +dark
            background: $n8
            color: $n2
    &:not(:last-child)
        margin-right: 8px

.wrapper
    display: flex
    align-items: flex-start
    +m
        display: block

.filters
    flex-shrink: 0
    width: 256px
    +m
        display: none
        width: 100%
        padding-bottom: 40px
    &.active
        display: block

.inner
    flex-grow: 1
    padding-left: 80px
    +x
        padding-left: 40px
    +d
        padding-left: 32px
    +m
        margin: 0 -16px
        padding: 0

.flight
    &:not(:last-child)
        margin-bottom: 24px

.btns
    margin-top: 40px
    text-align: center
    +m
        margin-top: 32px

.button
    .loader
        margin: 0 20px 0 4px