@import ../../../../styles/helpers

.title
    margin-bottom: 8px

.profile
    display: flex
    align-items: center
    margin-bottom: 24px
    padding-bottom: 24px
    border-bottom: 1px solid $n6
    +dark
        border-color: $n2
    span
        margin-right: 4px
        white-space: nowrap
        color: $n4

.avatar
    flex-shrink: 0
    width: 24px
    height: 24px
    margin: 0 8px
    img
        width: 100%
        height: 100%
        border-radius: 50%
        object-fit: cover

.name
    +body-bold-2
    
.parameters
    display: flex
    flex-wrap: wrap
    margin: -10px 0 40px -16px

.parameter
    display: flex
    align-items: center
    margin: 10px 0 0 16px
    color: $n4
    svg
        margin-right: 8px
        fill: $n4
    
.content
    margin-bottom: 48px
    +body-2
    color: $n4
    p
        &:not(:last-child)
            margin-bottom: 24px
        
.info
    margin-bottom: 32px
    +body-bold-1

.options
    position: relative
    display: flex
    flex-wrap: wrap
    margin: -24px 0 48px
    +m
        display: block
        margin-top: 0
    &:before
        content: ""
        position: absolute
        top: 24px
        left: 44%
        bottom: 0
        width: 1px
        background: $n6
        +dark
            background: $n2
        +m
            display: none

.option
    display: flex
    margin-top: 24px
    color: $n4
    svg
        margin-right: 16px
        fill: $n4
    &:nth-child(2n+1)
        flex: 0 0 44%
        padding-right: 40px
        +x
            padding-right: 24px
        +m
            padding: 0
    &:nth-child(2n)
        flex: 0 0 56%
        padding-left: 80px
        +x
            padding-left: 24px
        +m
            padding: 0
        
.button
    +dark
        box-shadow: inset 0 0 0 2px $n3
        &:hover
            background: $n3