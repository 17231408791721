@import ../../../styles/helpers

.section
    padding-bottom: 136px
    border-bottom: 1px solid $n6
    +d
        margin-bottom: 80px
        padding-bottom: 80px
    +m
        margin-bottom: 64px
        padding-bottom: 64px
    +dark
        border-color: $n2

.wrapper
    display: flex
    align-items: flex-start
    +t
        display: block

.details
    flex-grow: 1
    padding-right: 48px
    +t
        margin-bottom: 48px
        padding: 0

.receipt
    flex-shrink: 0
    +m
        margin: 0 -16px

.box
    &:not(:last-child)
        margin-bottom: 32px

.category
    margin-bottom: 12px
    +body-bold-1

.item
    display: flex
    align-items: center
    &:not(:last-child)
        margin-bottom: 24px
        padding-bottom: 24px
        border-bottom: 1px solid $n6
        +dark
            border-color: $n3
.tag_item
    display: flex
    align-items: center
    justify-content: space-evenly
    &:not(:last-child)
        margin-bottom: 24px
        padding-bottom: 24px
        border-bottom: 1px solid $n6
        +dark
            border-color: $n3

.wrap
    flex-grow: 1

.subtitle
    margin-bottom: 8px
    +body-bold-2

.content
    +caption-2
    color: $n4

.counter
    flex-shrink: 0
    width: 128px
    margin-left: 24px
    padding: 12px
    border-radius: 24px
    box-shadow: inset 0 0 0 1px $n6
    +dark
        box-shadow: inset 0 0 0 1px $n3

.table
    margin-bottom: 32px

.line
    display: flex
    padding: 15px 22px
    &:last-child
        background: $n7
        border-radius: 8px
        +dark
            background: $n3
        .cell
            &:first-child
                color: $n2
                +dark
                    color: $n8

.cell
    &:first-child
        margin-right: auto
        color: $n4
    &:nth-child(2)
        flex-shrink: 0
        margin-left: 20px
        text-align: right
        font-weight: 500

.foot
    display: flex
    flex-wrap: wrap
    align-items: center
    align-content: center
    flex-direction: column
    text-align: center

.pay
    display: flex
    margin-bottom: 8px
    flex-direction: column
    align-items: center

.note
    // display: flex
    // justify-content: center
    margin-top: 10px
    // margin-left: -40px
    +poppins
    +caption-2
    color: $n4
    svg
        margin-right: 8px
        fill: $n4

.reviews
    margin-left: 8px
    color: $n4

.number
    font-weight: 500

.rating
    display: flex
    align-items: center
    svg
        margin-right: 8px
        fill: $s3

.button
    width: 19em

.tag_button
    width: 8em
    margin-left: 8px
    // padding: 10px
