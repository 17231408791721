@import ../../styles/helpers

.head
    margin-bottom: 64px
    text-align: center
    +m
        margin-bottom: 32px

.category
    display: inline-block
    margin-bottom: 12px
    padding: 8px 8px 6px
    box-shadow: inset 0 0 0 2px $p4
    border-radius: 4px
    +hairline-2
    color: $p4
    +m
        display: none

.title
    margin-bottom: 48px
    +m
        margin-bottom: 32px

.nav
    display: flex
    justify-content: center
    +m
        display: none

.link
    display: flex
    padding: 6px 12px
    border-radius: 14px
    background: none
    +button-2
    color: $n4
    transition: all .2s
    &:hover
        color: $n3
        +dark
            color: $n6
    &.active
        background: $n3
        color: $n8
        +dark
            background: $n8
            color: $n2
    &:not(:last-child)
        margin-right: 8px

.dropdown
    text-align: left

.wrapper
    margin: 0 -12px
    +m
        margin: 0 -8px