@import ../../../styles/helpers

.wrapper
    margin: 0 -16px

.preview
    img
        width: 100%
        object-fit: cover
        border-radius: 16px
        +t
            height: 600px
        +m
            height: 300px