@import ../../styles/helpers

.inner
    position: relative

.container
    width: 900px

.head
    display: flex
    align-items: center
    margin-bottom: 48px
    padding-right: 120px
    +t
        flex-direction: column
        align-items: flex-start
    +m
        margin-bottom: 32px
        padding: 0

.title
    margin-right: 12px
    +m
        font-size: 32px

.dropdown
    +t
        margin-left: -24px

.wrapper
    margin: 0 -16px
    +s
        margin: 0 -8px
