@import ../../styles/helpers

.head
    display: flex
    align-items: center
    margin-bottom: 32px
    +m
        display: block

.title
    margin-right: auto
    +body-bold-1
    +m
        margin: 0 0 32px

.nav
    display: flex

.link
    display: flex
    padding: 6px 12px
    border-radius: 14px
    background: none
    +button-2
    color: $n4
    transition: all .2s
    svg
        margin-right: 8px
        fill: $n4
        transition: fill .2s
    &:hover
        color: $n3
        svg
            fill: $n3
        +dark
            color: $n6
    &.active
        background: $n3
        color: $n8
        svg
            fill: $n8
        +dark
            background: $n3
            color: $n8
    &:not(:last-child)
        margin-right: 8px

.item
    display: flex
    padding-top: 32px
    border-top: 1px solid $n6
    +dark
        border-color: $n2
    &:not(:first-child)
        margin-top: 32px

.avatar
    flex-shrink: 0
    width: 48px
    height: 48px
    margin-right: 20px
    img
        width: 100%
        height: 100%
        border-radius: 50%
        object-fit: cover

.details
    flex-grow: 1

.author
    margin-bottom: 4px
    font-weight: 500
    color: $n1
    +dark
        color: $n8

.content
    margin-bottom: 8px
    color: $n3
    +dark
        color: $n4

.foot
    display: flex
    align-items: center

.time
    +caption-2
    color: $n4

.actions
    display: flex

.action
    margin-left: 16px
    +poppins
    +caption-bold-2
    transition: color .2s
    &:hover
        color: $p1
    +dark
        color: $n8
        &:hover
            color: $p1

